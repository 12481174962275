<template>
  <div>
    <heade></heade>
    <div class="com" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="com_box">
        <div class="com-left">
          <div class="com-left-top">{{ theoryCourse.name }}</div>
          <div class="com_title_box">
            <div class="com_title nice-scroll">
              <div v-for="(items, indexs) in theoryCourse.division_category" :key="indexs" class="com_title_category">
                <div class="com-left-com">
                  <div class="com-left-com-item" v-for="(item, index) in items.name" :key="index">
                    {{ item }}
                  </div>
                </div>
                <div class="com-left-foot">
                  <div class="">
                    <div v-for="(itemc, indexc) in items.division" :key="indexc" @click="
                      getTheoryDivisionContentById(
                        itemc.division_content_id,
                        itemc.charge_mode
                      )
                      " :id="itemc.division_content_id">
                      <div v-if="indexs == 0 && indexc == 0 && content == ''" :id="'trigger' + index" v-trigger>
                        <div class="com-left-foot-top" :class="[
                          queryWhere.id == itemc.division_content_id
                            ? 'addclass'
                            : '',
                        ]">
                          {{ itemc.name[0] }}
                        </div>
                        <div class="com-left-foot-but" :class="queryWhere.id == itemc.division_content_id
                          ? 'addclass'
                          : ''
                          ">
                          {{ itemc.name[1] }}
                        </div>
                      </div>

                      <div>
                        <div class="com-left-foot-top" :class="[
                          queryWhere.id == itemc.division_content_id
                            ? 'addclass'
                            : '',
                        ]">
                          {{ itemc.name[0] }}
                        </div>
                        <div class="com-left-foot-but" :class="queryWhere.id == itemc.division_content_id
                          ? 'addclass'
                          : ''
                          ">
                          {{ itemc.name[1] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="com-right">
          <div class="nice-scroll content" v-html="content"></div>
        </div>
        <div class="com-btt nice-scroll">
          <div>
            <router-link v-for="(itemd, indexd) in theory" :key="indexd" :to="{
              path: '/theory/detail',
              query: { id: itemd.id },
            }" :class="itemd.id == theoryCourse.id
              ? 'com-bbt-item active'
              : 'com-bbt-item'
              ">︽ {{ itemd.name }} ︾</router-link>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import $store from "../../store";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { scroll } from "@utils/scroll";
import {
  getTheoryCourse,
  getTheoryCourses,
  getTheoryCourseDivisionContent,
} from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      theoryCourse: {},
      queryWhere: {},
      content: "",
      theory: [],
      backgroud: "",
      triggerClick: true,
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  mounted: function () {
    let that = this;
    getTheoryCourses({ limit: 30 }).then(function (res) {
      that.$set(that, "theory", res.data.list);
    });
    getTheoryCourse(that.queryWhere).then(function (res) {
      that.$set(that, "theoryCourse", res.data);
      that.$set(that, "content", res.data.content);
    });
  },

  created: function () {
    this.queryWhere = this.$route.query;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    theoryCourse() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },
  methods: {
    getTheoryDivisionContentById: function (id, charge_mode) {
      if (
        charge_mode == 1 ||
        ($store.state.app.userInfo != null &&
          $store.state.app.userInfo.is_empower)
      ) {
        let that = this;
        that.triggerClick = false;
        that.queryWhere = {};
        that.queryWhere.id = id;
        getTheoryCourseDivisionContent(that.queryWhere).then(function (res) {
          that.$set(that, "content", res.data.content);
        });
        // 实现滚动效果
        let top = document.getElementsByClassName("content")[0].scrollTop;
        const timeTop = setInterval(function () {
          document.getElementsByClassName("content")[0].scrollTop = top -= 50;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      } else {
        if (!$store.state.app.token) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content: "您还未登录！是否去登录？",
              btn: ["是", "否"],
              closeBtn: 0,
              skin: "layui-layer-molv",
              yes: function () {
                window.location.href = "/login";
              },
            });
          });
        } else if (!$store.state.app.userInfo.is_empower) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content:
                "该部分内容为课题实验校、示范区会员账号专属内容，如需加入课题实验，请点击下方“课题申报”链接，按照要求提交信息，经课题组审核批准后，方可成为课题实验校、示范区，获得专属课题账号，正常查看全部内容。",
              closeBtn: 0,
              btn: ["课题申报", "关闭"],
              success: function (layero) {
                var btn = layero.find(".layui-layer-btn");
                btn.find(".layui-layer-btn0").attr({
                  href: "/application/shenbaobiao",
                });
              },
            });
          });
        }
      }
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails,.layui-layer-shade").remove();
    layui.use(["layer"], function () {
      let layer = layui.layer;
      layer.closeAll();
    });
  },
  directives: {
    trigger: {
      inserted(el, binging) {
        el.click();
      },
    },
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  display: flex;
  flex-wrap: wrap;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.com-left-foot {
  display: flex;
  flex-direction: column;
}

.active {
  color: #900c0c;
}

.com_right {
  overflow-y: auto;
}

.addclass {
  color: #d8c8a5;
}

.com-left-top>div {
  font-family: "楷体";
  width: 38px;
  height: 300px;
  margin: -90px auto 0px;
  text-align: center;
  color: #e2cea0;
  font-size: 38px;
  display: flex;
  align-items: center;
}

.com_title_category+.com_title_category {
  margin-top: 40px;
}

.com-btt {
  overflow-x: auto;
  overflow-y: hidden;
}

.com-btt div {
  width: 1480px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}
</style>
